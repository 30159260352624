import { ComponentRef, ElementRef, Injectable } from '@angular/core'
import {  MatDrawer, MatSidenav } from '@angular/material/sidenav'
import { USERID_NOT_RECOGNIZED } from '../state/actions/errors.actions'

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private sidenav: MatSidenav
  private usersDrawer: MatDrawer
  private snapDrawer: MatDrawer

  constructor() {
   }

  public setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav
    }

  public sideNavToggle(): void {
    this.sidenav.toggle()
    }


  public setUsersDrawer(usersDrawer: MatDrawer) {
    this.usersDrawer = usersDrawer
    }

  public usersDrawerOpen() {
    return this.usersDrawer.open()
    }

  public setSnapDrawer(snapDrawer: MatDrawer) {
    this.snapDrawer = snapDrawer
    }

  public snapDrawerOpen() {
    return this.snapDrawer.open()
    }

 }
