<mat-toolbar class="navbar-bg">
  <button class="sidebar-toggle" (click)="toggleDrawer()" mat-icon-button aria-label="Example icon-button with menu icon">
    <i class="hamburger align-self-center"></i>
  </button>
  <section fxFlex fxLayout fxLayoutAlign="flex-end">
    <button mat-icon-button (click)="toLogout()">
      Sair
    </button>
  </section>
</mat-toolbar>

