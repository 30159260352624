export const environment = {
  mode: 'production',
  useEmulators: false,
  production: true,
  firebase: {
    apiKey: "AIzaSyCDaDS9PInzGiJ3kUDkP7igCDXqHLqMTg4",
    authDomain: "meli-production.firebaseapp.com",
    projectId: "meli-production",
    storageBucket: "meli-production.appspot.com",
    messagingSenderId: "946550598954",
    appId: "1:946550598954:web:be1d474d02478910dfc253",
    measurementId: "G-V1CSXF8F12"
  },
  algolia: {
    app_id: 'C3ECQ35K06',
    search_api_id: '9630ff078d706ca28d2ac0b14a5a6710',
    admin_api_id: 'baf796268dce996e257637194ba901f0'
    }
}
