import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, delay, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as SnackbarActions from '../actions/snackbar.actions'
import * as AuthActions from '../actions/auth.actions'
import * as RoutingActions from '../actions/routing.actions'
import * as ErrorsActions from '../actions/errors.actions'
import * as CompanyActions from '../actions/company.actions'
import * as UsersActions from '../actions/users.actions'
@Injectable()
export class SnackbarEffects {

  constructor(private actions$: Actions,
    private matSnackBar: MatSnackBar) {
    }

  closeSnackbar$ = createEffect( () =>
    this.actions$.pipe(
      ofType(SnackbarActions.SnackbarClose),
      tap(() => this.matSnackBar.dismiss())
      )
    )

  openSnackbar$ = createEffect( () =>
    this.actions$.pipe(
      ofType(SnackbarActions.SnackbarOpen),
      tap(payload => this.matSnackBar.open(payload.message, payload.action, { duration: 3000 }))
      ),
      { dispatch: false}
    )

  // AUTH
  logoutMessage$ = createEffect( () =>
  this.actions$.pipe(
    ofType(AuthActions.DisplayLogoutMessage),
    map( () => SnackbarActions.SnackbarOpen({ message: 'Adeus!'}))
    )
  )

  //Company CRUD
  baseDataCRUDSuccess$ = createEffect( () =>
    this.actions$.pipe(
      ofType(CompanyActions.ActionSuccess),
      concatMap( (action) => {
        switch (action.action) {
          case CompanyActions.COMPANY_UPDATED: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'As infromações de empresa foram atualizadas com sucesso'}),
              ]
            }
          case CompanyActions.LOGO_UPLOADED: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'O logo da empresa foi atualizado com sucesso'}),
              ]
            }
          default: {
            return  []
            }
          }
        })
      )
    )

  authActionsSuccess$ = createEffect( () =>
    this.actions$.pipe(
      ofType(AuthActions.ActionSuccess),
      concatMap( (action) => {
        switch (action.action) {
          case AuthActions.MELI_ACCOUNT_EXISTS: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'Existe uma conta cadastrada. Faça login'}),
              ]
            }
          default: {
            return  []
            }
          }
        })
      )
    )

  usersCRUDSuccess$ = createEffect( () =>
    this.actions$.pipe(
      ofType(UsersActions.ActionSuccess),
      concatMap( (action) => {
        switch (action.action) {
          case UsersActions.USER_CREATED: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'Usuário criado com sucesso'})
              ]
            }
          case UsersActions.USER_UPDATED: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'Usuário atualizado com sucesso'})
              ]
            }
          case UsersActions.USER_DELETED: {
            return [
              SnackbarActions.SnackbarOpen({ message: 'Usuário excluído com sucesso'})
              ]
            }
          default: {
            return  []
            }
          }
        })
      )
    )

  // Errors actions Failure
  errorsActionsFailure$ = createEffect( () =>
    this.actions$.pipe(
      ofType(ErrorsActions.ActionFailure),
      concatMap( (action) => {
        switch (action.error) {
          // AUTH
          case ErrorsActions.AUTH_USER_NOT_FOUND: {
            return [SnackbarActions.SnackbarOpen({ message: 'Não há registro correspondente a esse usuário.'})]
            }
          case ErrorsActions.AUTH_WRONG_PASSWORD: {
            return [ SnackbarActions.SnackbarOpen({message: 'A senha é inválida ou o usuário não tem uma senha.'}) ]
            }
          case ErrorsActions.AUTH_INVALID_EMAIL: {
            return [ SnackbarActions.SnackbarOpen({message: 'Ops! email está mal formatado'}) ]
            }
          case ErrorsActions.AUTH_EMAIL_IN_USED: {
            return [ SnackbarActions.SnackbarOpen({message: 'O endereço de e-mail já está sendo usado por outra conta'}) ]
            }
          case ErrorsActions.AUTH_WEAK_PASSWORD: {
            return [ SnackbarActions.SnackbarOpen({message: 'A senha deve ter pelo menos 6 caracteres'}) ]
            }
          case ErrorsActions.AUTH_USER_NOT_VALID: {
            return [ SnackbarActions.SnackbarOpen({message: 'Usuário não é valido'}) ]
            }
          case ErrorsActions.USER_NOT_IN_DATABASE: {
            return [ SnackbarActions.SnackbarOpen({message: 'Usuário não existe. Contatar administrador'}) ]
            }
          // SHARED
          case ErrorsActions.USERID_NOT_RECOGNIZED: {
            return [ SnackbarActions.SnackbarOpen({message: 'Erro carregando usuário, por favor tentar de novo'})]
            }
          case ErrorsActions.USERID_NOT_RECOGNIZED: {
            return [ SnackbarActions.SnackbarOpen({message: 'Esse item não existe. Contactar administrador'})]
            }
          // USERS
          case ErrorsActions.EMAIL_ALREADY_EXISTS: {
            return [ SnackbarActions.SnackbarOpen({message: 'O endereço de e-mail já está sendo usado por outra pessoa'})]
            }
          case ErrorsActions.INVALID_EMAIL: {
            return [ SnackbarActions.SnackbarOpen({message: 'O endereço de email está formatado incorretamente'})]
            }
          case ErrorsActions.INVALID_ARGUMENT: {
            return [ SnackbarActions.SnackbarOpen({message: 'O endereço de email está formatado incorretamente'})]
            }
          default: {
            return [ SnackbarActions.SnackbarOpen({message: 'Algo deu errado, por favor tentar de novo'}) ]
            }
          }
        })
      )
    )
}
