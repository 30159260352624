import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as RoutingActions from '../../state/actions/routing.actions'

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent {
  constructor(private store: Store<fromRoot.State>) { }
  goToLogin() {
    this.store.dispatch(RoutingActions.GotoLoginPage())
  }

  goToSignup() {
    this.store.dispatch(RoutingActions.GotoSignUpPage())
  }

  goToHome() {
    this.store.dispatch(RoutingActions.GoToHome())
  }
}
