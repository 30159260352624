import { Injectable } from "@angular/core"
import { ClientAdmin, SignupInfo, KeypairInfo, LoginInfo } from "../interfaces/auth.interface"
import * as ErrorActions from '../state/actions/errors.actions'
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { AdminUser } from "../interfaces/admin-user.interface"
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { UIService } from "./ui.service"
import { firstValueFrom, take } from "rxjs"
import { AUTHORIZE_AND_SET } from "../interfaces/firebase-functions.interface"
import { AngularFireFunctions } from "@angular/fire/compat/functions"

@Injectable()
export class AuthService {

  firestore = this.db.firestore

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private fn: AngularFireFunctions) { }

  async login(loginInfo: LoginInfo) {
    // console.log('AuthService - loginInfo - loginInfo: ', loginInfo)
    return new Promise(async (resolve, reject) => {
      try {
        await this.afAuth
          .signInWithEmailAndPassword(
            loginInfo.email,
            loginInfo.password
          )
        return resolve(true)
      } catch (error: any) {
        if (error.code === 'auth/wrong-password') {
          error = ErrorActions.AUTH_WRONG_PASSWORD
        }
        if (error.code === 'auth/user-not-found') {
          error = ErrorActions.AUTH_USER_NOT_FOUND
        }
        return reject(error)
      }
    })
  }


  async userLoginSetup(uid: string) {
    // console.log('AuthService - userLoginSetup')
    return new Promise(async (resolve, reject) => {
      try {
        let authInterface: ClientAdmin
        const keypairDoc = await this.firestore.doc('keypairs/' + uid).get()
        const keyPair: KeypairInfo = keypairDoc.data() as KeypairInfo
        if (keyPair.role === 'admin') {
          // console.log('AuthService - userLoginSetup - keypairs exist - user is admin: ', keyPair)
          const [clientDoc, adminDoc] = await Promise.all([
            this.firestore.doc('clients/' + keyPair.clientId).get(),
            this.firestore.doc('clients/' + keyPair.clientId + '/admins/' + uid).get()])
          const client = clientDoc.data() as any
          const admin   = adminDoc.data()
          authInterface = {
            clientId:         keyPair.clientId,
            clientName:       keyPair.clientName,
            adminId:          uid,
            productsActive:   client.productsActive,
            productsInactive: client.productsInactive,
            productsPaused:   client.productsPaused,
            email:            admin.email,
            firstName:        admin.firstName,
            lastName:         admin.lastName,
            meliUserId:       client.meliUserId,
            meliConnect:      client.meliConnect,

            }
          return resolve(authInterface)
        } else {
          return reject(ErrorActions.AUTH_USER_NOT_VALID)
        }
      } catch (error) {
        return reject(error)
      }
    })
  }

  // Creates Admin user account
  async createAdminUserAuth(signupInfo: SignupInfo): Promise<ClientAdmin> {
    // console.log('createAdminUserAuth: ', signupInfo)
    return new Promise(async (resolve, reject) => {
      try {
        // Admin user info
        const newAdminUser: AdminUser = ({
          firstName:  signupInfo.firstName,
          lastName:   signupInfo.lastName,
          clientName: signupInfo.clientName,
          email:      signupInfo.email,
          role:       'admin',
          deleted:    false,
          state:      'active',
          activeAt:   new Date(),
          createdAt:  new Date()
        })
        const createAuthResult = await this.afAuth.createUserWithEmailAndPassword(newAdminUser.email, signupInfo.password)
        newAdminUser.id       = createAuthResult.user.uid
        newAdminUser.password = signupInfo.password

        const signUpInfo: SignupInfo = {
          id:         newAdminUser.id,
          email:      signupInfo.email,
          password:   signupInfo.password,
          firstName:  signupInfo.firstName,
          lastName:   signupInfo.lastName,
          clientName: signupInfo.clientName,
          role:       signupInfo.role,
          deleted:    false,
          state:      'active',
          meliConnect: false
        }
        const clientUniqueId = await this.createAdminUserProfile(signUpInfo)

        const result: ClientAdmin = {
          email:            signupInfo.email,
          adminId:          newAdminUser.id,
          clientId:         clientUniqueId,
          clientName:       signupInfo.clientName,
          meliConnect:      false,
          meliUserId:       null,
          productsActive:   0,
          productsInactive: 0,
          productsPaused:   0,
          firstName:        signupInfo.firstName,
          lastName:         signupInfo.lastName,
        }
        return resolve(result)
      } catch (error) {
        console.log(error)
        return reject(error)
      }
    })
  }

  // Creates client
  async createAdminUserProfile(signupInfo: SignupInfo): Promise<string> {
    // console.log('SignupService - createAdminUserProfile: ', signupInfo)

    return new Promise(async (resolve, reject) => {
      try {

        const clientUniqueId = this.db.createId()

        // Inits client info for admin user
        const clientInfo = ({
          name: signupInfo.clientName,
          createdAt: new Date(),
          meliConnect: false,
          productsActive:   0,
          productsInactive: 0,
          productsPaused:   0
        })

        // Inits keypair for adminuser
        const keypairInfo: KeypairInfo = ({
          userId:     signupInfo.id,
          clientId:   clientUniqueId,
          clientName: signupInfo.clientName,
          createdAt:  new Date(),
          role:       'admin'
        })

        // Get a new write batch
        const batch = this.db.firestore.batch()

        // 1. Adds to batch create client info
        const clientRef = this.db.collection('clients').doc(clientUniqueId).ref
        batch.set(clientRef, clientInfo)

        // 2. Adds to batch creates adminuser keypair
        const adminKeypairRef = this.db.collection('keypairs').doc(signupInfo.id).ref
        batch.set(adminKeypairRef, keypairInfo)

        // 3. Adds to batch creates adminuser document
        const adminUserRef = this.db.collection('clients/' + clientUniqueId + '/admins').doc(signupInfo.id).ref
        batch.set(adminUserRef, {
          firstName:  signupInfo.firstName,
          lastName:   signupInfo.lastName,
          clientName: signupInfo.clientName,
          clientId:   clientUniqueId,
          email:      signupInfo.email,
          role:       signupInfo.role,
          deleted:    signupInfo.deleted,
          state:      signupInfo.state,
          createdAt:  new Date(),
          id:         signupInfo.id,
          password:   signupInfo.password
        })

        // Commit the batch
        await batch.commit()

        resolve(clientUniqueId)
      } catch (error) {
        return reject(error)
      }
    })
  }

  async logout() {
    // console.log('AuthService - logout')
    return new Promise(async (resolve, reject) => {
      try {
        await this.afAuth.signOut()
        return resolve(true)
      } catch (error) {
        return reject(error)
      }
    })
  }

  async getProductsCounts(clientId: string): Promise<ClientAdmin> {
    const clientDoc   = await this.firestore.doc('clients/' + clientId).get()
    const client      = clientDoc.data() as ClientAdmin
    return client
  }

  authorizeAccountAndSet(authToken: string, clientId: string): Promise<string> {
    console.log('authorizeAccountAndSet', authToken, clientId)
    return new Promise(async (resolve, reject) => {
      try {
        const connectAccount$ = this.fn.httpsCallable(AUTHORIZE_AND_SET)
        const result = await firstValueFrom(connectAccount$({authToken: authToken, clientId: clientId}).pipe(take(1)))
        // state: false, redirectUri: redirectUri
        console.log(result)
        resolve(result)
      } catch (error) {
        return reject(error)
        }
      })
    }


}
