import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { MaterialModule } from './material.module'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '../environments/environment'
import { EffectsModule } from '@ngrx/effects'
import { AuthService } from './services/auth.service'
import { reducers } from './state/reducers/app.reducer'
import { AuthModule } from './auth/auth.module'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { LoginComponent } from './auth/login/login.component'
import { SignupComponent } from './auth/signup/signup.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule, registerLocaleData } from '@angular/common'
import { SidebarComponent } from './navigation/sidebar/sidebar.component'
import { NavbarComponent } from './navigation/navbar/navbar.component'
import { DialogConfirmationComponent } from './shared/dialog-confirmation/dialog-confirmation.component'
import { IconsModule } from './icons.module'
import { SnackbarEffects } from './state/effects/snackbar.effects'
import { ErrorsEffects } from './state/effects/errors.effects'
import { DialogsEffects } from './state/effects/dialog.effects'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AuthGuard } from './auth/auth.guard'
import { BackofficeComponent } from './backoffice/backoffice.component'
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions'
import { FlexLayoutModule } from '@angular/flex-layout'
import { RoutingEffects } from './state/effects/routing.effects'
import localePt from '@angular/common/locales/pt'
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth'
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore'
import { MeliComponent } from './auth/meli/meli.component'
import { MeliOutComponent } from './auth/meli-out/meli-out.component'
import { initializeApp } from 'firebase/app'
import { provideFirebaseApp } from '@angular/fire/app'
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore'
import { FIREBASE_OPTIONS } from '@angular/fire/compat'
import { AccountEffects } from './state/effects/account.effects'
import { AccountService } from './services/account.service'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { TopbarComponent } from './home/topbar/topbar.component'
import { IndexComponent } from './home/index/index.component'
import { UnauthGuard } from './auth/unauth.guard'
import * as Sentry from "@sentry/angular-ivy"
import { Router } from '@angular/router';
import { FaqComponent } from './home/faq/faq.component';
import { FooterComponent } from './home/footer/footer.component'

registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    AppComponent,
    DialogConfirmationComponent,
    SidebarComponent,
    SignupComponent,
    LoginComponent,
    BackofficeComponent,
    MeliComponent,
    MeliOutComponent,
    NavbarComponent,
    IndexComponent,
    TopbarComponent, FaqComponent, FooterComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([SnackbarEffects, ErrorsEffects, DialogsEffects, RoutingEffects]),
    StoreRouterConnectingModule.forRoot(),
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAuthModule,
    // AngularFireFunctionsModule,
    // AngularFirestoreModule.enablePersistence(),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      // connectFirestoreEmulator(firestore, 'localhost', 8080);
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    NgbModule,
    AuthModule,
    IconsModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthService,
    AccountService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    AuthGuard,
    UnauthGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
