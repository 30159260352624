<section class="reset-pasw-container -container" fxLayout fxLayout="row" fxLayoutAlign="center start">
    <mat-card fxFlex.xs="100%" fxFlex="400px">
      <mat-card-header>
        <mat-card-title><h1>Esqueci minha senha</h1></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form class="reset-pasw-form" fxLayoutGap="10px" [formGroup]="resetPasswForm" (ngSubmit)="onSubmit()" >
        
          <mat-form-field>
            <input type="email" matInput placeholder="Digite seu email" formControlName="email">
            <mat-error>email nome não pode estar em branco.</mat-error>
          </mat-form-field>
  
          <button type="submit" mat-raised-button color="warn" [disabled]="resetPasswForm.invalid">Entrar</button>
        </form>
        <mat-spinner *ngIf="isSpinner$ | async" ></mat-spinner>
      </mat-card-content>
      
    </mat-card>
    
  </section>