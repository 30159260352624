<section class="py-5 bg-white">
  <div class="container">
    <div class="mb-5 text-center">
      <h2 class="h1">Perguntas Frequentes</h2>
      <p class="text-muted fs-lg">Aqui estão algumas das respostas que você pode estar procurando.</p>
    </div>

    <div class="row">
      <div class="col-md-9 col-lg-8 mx-auto">
        <div class="accordion" id="accordionFaq">
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqOne" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <h6 class="mb-0">
                O que é o Melispot Beta?
              </h6>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="faqOne" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                O Melispot Beta é uma ferramenta inovadora projetada para ajudar vendedores do Mercado Livre a monitorar e maximizar o desempenho de seus produtos. É uma versão inicial da nossa plataforma que está disponível gratuitamente durante o período beta.
              </div>
            </div>
          </div>
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqFives" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
              <h6 class="mb-0">
                É seguro vincular minha conta do Mercado Livre?
              </h6>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="faqFives" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                Sim, é seguro vincular sua conta do Mercado Livre ao Melispot. Seguimos protocolos rigorosos de segurança e proteção de dados para garantir a confidencialidade das suas informações.
              </div>
            </div>
          </div>
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqTwo" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
              <h6 class="mb-0">
                Quais tipos de atualizações o Melispot rastreia?
              </h6>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="faqTwo" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                O Melispot rastreia mais de 15 tipos de atualizações que podem impactar seus produtos no Mercado Livre. Isso inclui mudanças de preço, título, status de promoção, descrição do produto, atributos da ficha técnica, imagens, vídeos, tipos de envio, garantias e muito mais.
              </div>
            </div>
          </div>
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqThree" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
              <h6 class="mb-0">
                Como faço para me tornar um tester inicial?
              </h6>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="faqThree" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                Para se tornar um tester inicial do Melispot Beta, basta criar uma conta gratuita e começar a explorar nossas métricas. Você terá a oportunidade de fornecer feedback valioso à medida que usa a ferramenta.              </div>
            </div>
          </div>
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqFour" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
              <h6 class="mb-0">
                Quais são os benefícios de usar o Melispot Beta?
              </h6>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="faqFour" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                O Melispot Beta permite que você identifique rapidamente o impacto de mudanças em seus produtos, otimize suas listagens e compreenda melhor o que está afetando suas vendas no Mercado Livre. Nossas métricas claras e insights ajudam a impulsionar seus negócios.
              </div>
            </div>
          </div>

          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqMel" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
              <h6 class="mb-0">
                Quanto custa o Melispot Beta após o período beta?
              </h6>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="faqMel" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                Durante o período beta, o Melispot Beta é totalmente gratuito para os usuários. Estamos trabalhando para aprimorar a ferramenta com base no feedback dos usuários, e quaisquer atualizações sobre os planos de preços serão comunicadas oportunamente.              </div>
            </div>
          </div>
          <div class="card border mb-3">
            <div class="card-header cursor-pointer" id="faqMel1" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
              <h6 class="mb-0">
                Como posso obter suporte em caso de dúvidas ou problemas?              </h6>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="faqMel2" data-bs-parent="#accordionFaq">
              <div class="card-body py-3">
                Para obter suporte, você pode entrar em contato com nossa equipe de suporte por meio do formulário de contato em nossa plataforma. Estamos aqui para ajudar a solucionar suas dúvidas e fornecer assistência quando necessário.              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
