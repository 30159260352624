import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StoreModule } from '@ngrx/store'
import { MaterialModule } from '../material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { AuthReducer } from '../state/reducers/auth.reducer'
import { AuthService } from '../services/auth.service'
import { AuthRoutingModule } from './auth-routing.module'
import { EffectsModule } from '@ngrx/effects'
import { AuthEffects } from '../state/effects/auth.effects'
import { UIService } from '../services/ui.service';
import { OnboardingComponent } from '../account/onboarding/onboarding.component'

@NgModule({
    declarations: [
        ResetPasswordComponent
      ],
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('auth', AuthReducer),
        EffectsModule.forFeature([AuthEffects]),
        AuthRoutingModule
    ],
    providers: [
        AuthService,
        UIService
    ]
})
  export class AuthModule {}
