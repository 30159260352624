import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatSidenav } from '@angular/material/sidenav'
import { Router } from '@angular/router'
import { SharedService } from '../services/shared.service'

@Component({
  selector: 'app-backoffice',
  templateUrl: './backoffice.component.html',
  styleUrls: ['./backoffice.component.css']
})
export class BackofficeComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav') public sidenav: MatSidenav
  constructor(private sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
    // console.log('BackofficeComponent - ngOnInit')
    }

  ngAfterViewInit(): void {
    // console.log('BackofficeComponent - ngAfterViewInit')
    this.sharedService.setSidenav(this.sidenav)
    }

  goToDashboard() {
    this.router.navigate(['dashboard', 'list'])
    }

}
