import { Action, createReducer, on } from "@ngrx/store"
import * as UIActions from '../actions/ui.actions'
import * as AuthActions from '../actions/auth.actions'
import * as ErrorsActions from '../actions/errors.actions'
import * as CompanyActions from '../actions/company.actions'
import * as UsersActions from '../actions/users.actions'
import * as EventsActions from '../actions/events.actions'
import * as ItemsActions from '../actions/items.actions'
import * as ItemEventsctions from '../actions/item-events.actions'
import * as AccountActions from '../actions/account.actions'
import * as VisitsActions from '../actions/visits.actions'
import * as OrdersActions from '../actions/orders.actions'
import * as QuestionsActions from '../actions/questions.actions'
import * as DashboardActions from '../actions/dashboard.actions'
import * as MetricsdActions from '../actions/metrics.actions'

export interface UIState {
    isLoader: boolean
    isSpinner: boolean
    itemSpinner: boolean
}

const initialState: UIState = {
    isLoader:   false,
    isSpinner:  false,
    itemSpinner: false
}

const uiReducer = createReducer(
  initialState,
  on(UIActions.StartLoader,   state => ({ ...state, isLoader: true})),
  on(UIActions.StopLoader,    state => ({ ...state, isLoader: false})),
  on(UIActions.StartSpinner,  state => ({ ...state, isSpinner: true})),
  on(UIActions.StopSpinner,   state => ({ ...state, isSpinner: false})),
  on(
    // Auth
    AuthActions.OnResetPassword,
    AuthActions.Login,
    AuthActions.Signup,
    AccountActions.GetMercadoLivrePermissionsUrl,
    AccountActions.FireIinitalImportFromMeli,
    // Base Data
    CompanyActions.UpdateCompanyInfo,
    UsersActions.CreateUser,
    UsersActions.UpdateUser,
    UsersActions.DeleteUser,
    UsersActions.CreateUsersByCSV,
    EventsActions.LoadEvents,
    ItemEventsctions.LoadItemEvents,
    VisitsActions.LoadVisits,
    OrdersActions.LoadOrders,
    QuestionsActions.LoadQuestions,
    DashboardActions.SearchQueryItems,
    DashboardActions.SearchQueryEvents,
    DashboardActions.GetDashboardMetrics,
    state => ({ ...state, isSpinner: true})),
  on(
    // Auth
    AuthActions.SetAuthenticated,
    AuthActions.OnSignupSuccess,
    AuthActions.OnResetPasswordSuccess,
    CompanyActions.ActionSuccess,
    UsersActions.ActionSuccess,
    UsersActions.CSVFileProcessFinished,
    EventsActions.Add,
    // Errors Default Action Fail
    ErrorsActions.ActionFailure,
    ItemsActions.AddTrends,
    AccountActions.AddRedirectURI,
    AccountActions.FinishOnboardReport,
    ItemEventsctions.Add,
    VisitsActions.Add,
    OrdersActions.Add,
    QuestionsActions.Add,
    MetricsdActions.ActionSuccess,
    DashboardActions.ActionSuccess,
    state => ({ ...state, isSpinner: false})),
  on(
    UsersActions.LoadUsers,
    CompanyActions.LoadCompany,
    state => ({ ...state, isLoader: true})),
  on(
    UsersActions.ActionSuccess,
    CompanyActions.ActionSuccess,
    state => ({ ...state, isLoader: false})),
  on(
    ItemsActions.FollowItem,
    ItemsActions.UnfollowItem,
    state => ({ ...state, itemSpinner: true})),
  on(
    ItemsActions.UdpateFollowingItemsState,
    state => ({ ...state, itemSpinner: false}))

  )

export function UIReducer(state: UIState | undefined, action: Action) {
  return uiReducer(state, action)
  }

export const getIsLoader    = (state: UIState) => state.isLoader
export const getIsSpinner   = (state: UIState) => state.isSpinner
export const getHideButton   = (state: UIState) => !state.isSpinner
export const getItemSipnner   = (state: UIState) => state.itemSpinner
