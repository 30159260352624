import { createAction, props } from '@ngrx/store'
import { User } from 'src/app/interfaces/user.interface'
export const CloseDialog    = createAction('[Dialogs] Close')
export const OpenDialog     = createAction('[Dialogs] Open', props<{
  element?:     User,
  elementId?:    string,
  elementType:  string,
  action:       string
  }>())

