import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs'
import * as fromRoot from './state/reducers/app.reducer'
import * as AuthActions from './state/actions/auth.actions'
import { AngularFireAuth } from '@angular/fire/compat/auth'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isAuth$: Observable<boolean>
  currentRouteisMeliIn$: Observable<boolean>

  constructor(private store: Store<fromRoot.State>,
              private afAuth: AngularFireAuth) {
    }

  async ngOnInit(): Promise<void> {
    console.log('Wunder Panel Admin v.0.1 - 29/12')
    this.isAuth$   = this.store.select(fromRoot.getIsAuthenticated)

    this.afAuth.authState.subscribe( async firebaseAuthUser => {
      if (firebaseAuthUser) {
        console.log('firebaseAuthUser exists')
        this.store.dispatch(AuthActions.UserLoginSetup({uid: firebaseAuthUser.uid}))
      } else {
        console.log('firebaseAuthUser doesnt exists')
        this.store.dispatch(AuthActions.SetNotAuthenticated())
        }
      })
    this.currentRouteisMeliIn$ = this.store.select(fromRoot.isMeliIn)
    }

}
