import { Component, Inject, OnInit} from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { SharedService } from '../../services/shared.service'
import * as AuthActions from '../../state/actions/auth.actions'
import * as fromRoot from '../../state/reducers/app.reducer'
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  constructor(
    private _router: Router,
    private _store: Store,
    private _sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  goToAdmin() {
    this._router.navigate(['admin'])
    }

  toLogout() {
    // console.log('HeaderComponent - toLogout: ')
    this._store.dispatch(AuthActions.Logout())
  }

  toggleDrawer() {
    this._sharedService.sideNavToggle()
  }

}
