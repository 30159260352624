import { createAction, props } from "@ngrx/store"
import { ItemEvent } from "src/app/interfaces/item-event.interface"
import { EventType } from "src/app/interfaces/item.interface"

export const LoadItemEvents  = createAction('[ItemEvents] Load ItemEvents', props<{startDate: Date, endDate: Date, itemId: string, events: EventType[]}>())
export const LoadItemEventsByQuery  = createAction('[ItemEvents] Load ItemEvents by query', props<{startDate: Date, endDate: Date, query: string, events: EventType[]}>())

/// NGRX ENTITY ///
export const Add  = createAction('[ItemEvents] added', props<{itemEvents: ItemEvent[]}>())
export const RemoveAll  = createAction('[ItemEvents] remove all')
