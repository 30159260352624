import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { AuthService } from '../../services/auth.service'
import * as AuthActions from '../actions/auth.actions'
import * as AccountActions from '../actions/account.actions'
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { from, of } from 'rxjs'
import * as ErrorsActions from '../actions/errors.actions'
import * as RoutingActions from '../actions/routing.actions'
import { Store } from '@ngrx/store'
import * as fromRoot from '../reducers/app.reducer'

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Login),
      switchMap((action) => from(this.authService.login(action.loginInfo))
        .pipe(
          map(() => AuthActions.LoginSuccess()),
          catchError((error) => of(ErrorsActions.ActionFailure({ error: error, func: 'authService.login' })))
        )
      )
    )
  )

  userLoginSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.UserLoginSetup),
      switchMap((payload) => from(this.authService.userLoginSetup(payload.uid))
        .pipe(
          map((result: any) => {
            return AuthActions.SetAuthenticated({ authInterface: result, isConnectedToMeli: result.meliConnect })
          }),
          catchError((error) => of(ErrorsActions.ActionFailure({ error: error, func: 'authService.userLoginSetup' })))
        )
      )
    )
  )

  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Signup),
      switchMap((payload) => from(this.authService.createAdminUserAuth(payload.signupInfo))
        .pipe(
          map((payload) => AuthActions.OnSignupSuccess({ authInterface: payload })),
          catchError((error) => of(ErrorsActions.ActionFailure({ error: error, func: 'authService.createAdminUserAuth' })))
        )
      )
    )
  )

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Logout),
      switchMap(() => from(this.authService.logout())
        .pipe(
          map(() => AuthActions.DisplayLogoutMessage()),
          catchError((error) => of(ErrorsActions.ActionFailure({ error: error, func: 'authService.logout' })))
        )
      )
    )
  )

  onSignupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.OnSignupSuccess),
      mergeMap((payload) => of(
        AuthActions.SetAuthenticated({ authInterface: payload.authInterface, isConnectedToMeli: false })
      ))
    )
);

  onSetNotAuthenticated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.SetNotAuthenticated),
      map((payload) => RoutingActions.GoToHome()))
  )

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store
  ) { }

  // This is used as the callback URL from meradolibre, needs to be on a module which is always initialized
  // This is used only in Meli-in
  authorizeAccountAndSet$ = createEffect( () =>
    this.actions$.pipe(
      ofType(AuthActions.AuthorizeAccountAndSet),
      withLatestFrom(this.store.select(fromRoot.getClientId), this.store.select(fromRoot.getAuthToken)),
      switchMap( ([action, clientId, authToken])  => from(this.authService.authorizeAccountAndSet(authToken, clientId))
        .pipe(
          map( (payload) => AuthActions.MeliConnected({meliUserId: payload})),
          catchError((error) => of(ErrorsActions.ActionFailure({error: error, func: 'authService.AuthorizeAccountAndSet'})))
          )
        )
      )
    )

}
