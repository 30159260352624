
<app-topbar></app-topbar>
<section class="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
  <div class="alert alert-primary alert-dismissible fade show text-center mt-3" role="alert">
    <div class="container mt-3">
      <div class="col">
        <h4 class="alert-heading mb-3">🚀 Participe do lançamento beta como tester inicial. Seu feedback é crucial, completamente gratuito!</h4>
      </div>
      <div class="col mb-2">
      </div>
    </div>
  </div>
  <div class="landing-intro-content container">
    <div class="row align-items-center mt-5">
      <div class="col-lg-5 mx-auto">
        <h2 class="font-weight-bold my-4" style="font-size: 2.3rem;">Acelere seu sucesso no Mercado Livre</h2>
        <p class="text-lg">Monitore mudanças críticas em seus produtos, como alterações de preços, promoções, atualizações de ficha técnica, garantias e condições de envio.</p>
        <ul class="text-lg mt-4">
          <li><strong>Visualize</strong> imediatamente o efeito das mudanças nas suas vendas.</li>
          <li><strong>Investigue</strong> se modificações no preço, promoções novas ou imagens de capa aumentam o tráfego dos visitantes.</li>
          <li><strong>Entenda</strong> o que pode estar afetando negativamente a atração para um produto.</li>
        </ul>
      </div>
      <div class="col-md-6 order-md-2 d-flex justify-content-center">
        <div class="row h-100 align-items-center form-container">
          <svg fill="#79A6F0" height="50px" width="5d0px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512" xml:space="preserve">
              <g>
                <g>
                  <path d="M151.805,367.59c5.652,45.287,40.13,81.606,84.502,90.027V512h39.385v-54.384c44.372-8.42,78.851-44.74,84.502-90.027
                    H151.805z"/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M249.863,0.117C165.601,3.316,98.358,73.413,98.462,157.735c0.052,42.703,17.097,81.422,44.733,109.768
                    c16.422,16.842,27.48,37.956,31.884,60.702h61.229v-83.741l-46.744-46.744l27.849-27.849L256,208.459l38.589-38.589l27.849,27.849
                    l-46.746,46.746v83.741h61.231c4.41-22.755,15.482-43.874,31.908-60.728c27.664-28.384,44.707-67.17,44.707-109.938
                    C413.538,68.495,339.665-3.294,249.863,0.117z"/>
                </g>
              </g>
              <g>
                <line x1="256" y1="0" x2="256" y2="50" style="stroke:#ff00bf;stroke-width:2" />
                <line x1="512" y1="256" x2="462" y2="256" style="stroke:#FFFF00;stroke-width:2" />
                <line x1="256" y1="512" x2="256" y2="462" style="stroke:#FFFF00;stroke-width:2" />
                <line x1="0" y1="256" x2="50" y2="256" style="stroke:#FFFF00;stroke-width:2" />
              </g>
            </svg>
          <p class="text-center h4 mb-4 mt-4">Crie sua conta</p>
          <div class="justify-content-center">
            <form *ngIf="isSpinner$" action="" class="justify-content-center mx-auto" [formGroup]="signupForm"
              (ngSubmit)="onSubmit()">
              <!-- First Name  -->
              <div>
                <input type="text" class="form-control w-50 mx-auto shadow-none" formControlName="firstName"
                  placeholder="Nome">
                <p class="text-danger text-center"
                  *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid &&  firstName.errors?.['required']">
                  Nome
                  não pode estar em branco.</p>
              </div>
              <!-- Last Name -->
              <div class="mt-3 justify-content-center">
                <input type="text" class="form-control w-50 mx-auto shadow-none" formControlName="lastName"
                  placeholder="Sobrenome">
                <p class="text-danger text-center"
                  *ngIf="(lastName.touched || lastName.dirty) && lastName.invalid &&  lastName.errors?.['required']">a
                  senha não pode estar em branco.</p>
              </div>
              <!-- Clent Name -->
              <div class="mt-3 justify-content-center">
                <input type="text" class="form-control w-50 mx-auto shadow-none" formControlName="client"
                  placeholder="Empresa">
                <p class="text-danger text-center"
                  *ngIf="(clientName.touched || clientName.dirty) && clientName.invalid &&  clientName.errors?.['required']">
                  Empresa nome
                  não pode estar em branco.</p>
              </div>
              <!-- Email -->
              <div class="mt-3 justify-content-center">
                <input type="Email" class="form-control w-50 mx-auto shadow-none" formControlName="email"
                  placeholder="Email">
                <p class="text-danger text-center"
                  *ngIf="(email.dirty ||email.touched) && email.invalid &&  email.errors?.['required']">
                  email nome não pode
                  estar em branco.</p>
              </div>
              <!-- Password -->
              <div class="mt-3 justify-content-center">
                <input type="password" class="form-control w-50 mx-auto shadow-none" formControlName="password"
                  placeholder="Senha">
                <p class="text-danger text-center"
                  *ngIf="password.touched && password.invalid &&  password.errors?.['required']">a senha
                  não pode estar em branco.</p>
              </div>
              <!-- Signup Button -->
              <div class="text-center mt-3">
                <button [disabled]="signupForm.invalid" class="btn w-25 btn-primary rounded-pill">
                  Criar Conta
                </button>
              </div>
            </form>
            <p class="text-center mt-3">
              Já tem uma conta?
              <a (click)="goToLogin()" class="link">
                Log In
              </a>
            </p>
            <p class="text-center mt-4">See here the terms and conditions of use of the application and the notice of
              Privacy
            </p>
            <div class="d-flex justify-content-center">
              <mat-spinner *ngIf="isSpinner$ | async" [diameter]="25"></mat-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-faq></app-faq>


<app-footer></app-footer>
