import { createAction, props } from "@ngrx/store"
import { FollowingItem, Item, Trend } from "src/app/interfaces/item.interface"

export const LoadItems    = createAction('[Items] Load Items')
export const LoadTrends   = createAction('[Items] Load trends', props<{trendDir: string}>())
export const LoadItem     = createAction('[Items] Load Item', props<{itemId: string}>())
export const AddTrends    = createAction('[Items] trends added', props<{trends: Trend[]}>())
export const RemoveTrends = createAction('[Items] Remove Items')
export const FollowItem   = createAction('[Items] Follow Item', props<{id: string, title: string}>())
export const UnfollowItem   = createAction('[Items] Unfollow Item', props<{id: string}>())
export const LoadFollowItems = createAction('[Items] Load Follow Items')
export const UdpateFollowingItemsState = createAction('[Items] Update Following Items state', props<{items: FollowingItem[]}>())
/// NGRX ENTITY ///
export const Add  = createAction('[Items] added', props<{items: Item[]}>())
export const AddItem  = createAction('[Items] add item', props<{item: Item}>())
