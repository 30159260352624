import { createAction, props } from "@ngrx/store"
import { ItemEvent } from "src/app/interfaces/item-event.interface"
import { Item, QueryObject, Trend } from "src/app/interfaces/item.interface"
import { Metrics } from "src/app/interfaces/metrics.interface"
import { Order, OrdersCountByDate } from "src/app/interfaces/order.interface"
import { Question } from "src/app/interfaces/question.interface"
import { Visit } from "src/app/interfaces/visit.interface"

export const ActionSuccess        = createAction('[Dashboard] Action Success', props<{action: string, elements?: Item[] | Order[] | Question[] | Visit[] | ItemEvent[] | Trend[] | OrdersCountByDate[] | Metrics}>())
export const SearchQueryItems     = createAction('[Dashboard] Search query items', props<{queryObject: QueryObject}>())
export const SearchQueryEvents    = createAction('[Dashboard] Search query events', props<{queryObject: QueryObject}>())
export const GetDashboardMetrics  = createAction('[Dashboard] Get Dashboard Metrics', props<{span?: string}>())

// ACTION TYPES
export const ITEMS_LOADED     = 'Items loaded'
export const ITEM_LOADED     = 'Item loaded'
export const ORDERS_LOADED    = 'Orders loaded'
export const ORDERS_COUNT_BY_DATE_LOADED = 'Orders count by date loaded'
export const QUESTIONS_LOADED = 'Questions loaded'
export const VISITS_LOADED    = 'Visits loaded'
export const ITEM_EVENTS_LOADED    = 'Item events loaded'
export const TRENDS_LOADED    = 'Trends loaded'
export const CLIENT_INFO_LOADED    = 'Client infoloaded'
export const METRICS_LOADED    = 'Metrics loaded'
export const ITEM_FOLLOWED    = 'Following item'
export const ITEM_UNFOLLOWED    = 'Unfollowing item'
