import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store"
import * as AccountActions from '../actions/account.actions'
import { OnboardingReport } from "src/app/interfaces/account.interface"

export interface AccountState {
  onboardingReport: OnboardingReport
  meliAuthUrl:      string
  meliState:        boolean
  }

export const initialState: AccountState = {
  onboardingReport: null,
  meliAuthUrl:      null,
  meliState:        null
  }

const accountReducer = createReducer(
  initialState,
  on(AccountActions.UpdateOnboardingReport, (state, { report }) => ({
    ...state,
    onboardingReport: report
    })),
  on(AccountActions.AddRedirectURI, (state, { url, meliState }) => ({
    ...state,
    meliAuthUrl:  url,
    meliState: meliState
    }))
  )

export function AccountReducer(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action)
}

export const getAccountState = createFeatureSelector<AccountState>('account')
export const getOnboardingReport = createSelector(
  getAccountState,
  (state: AccountState) => state.onboardingReport
)

export const getMeliAuthURL = createSelector(
  getAccountState,
  (state: AccountState) => state.meliAuthUrl
)
