import { Component } from '@angular/core'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as RoutingActions from '../../state/actions/routing.actions'
import { Store } from '@ngrx/store'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  constructor(private store: Store<fromRoot.State>) { }
  goToSignup() {
    this.store.dispatch(RoutingActions.GotoSignUpPage())
  }
}
