import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '../state/reducers/app.reducer'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private store: Store<fromRoot.State>) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.store.select(fromRoot.getIsAuthenticated).pipe(take(1))
    }
}
