<div class="sidebar-content js-simplebar">
    <a class="sidebar-brand landing-brand" href="/dashboard">
      <svg fill="#FFFF00" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512" xml:space="preserve">
        <g>
          <g>
            <path d="M151.805,367.59c5.652,45.287,40.13,81.606,84.502,90.027V512h39.385v-54.384c44.372-8.42,78.851-44.74,84.502-90.027
              H151.805z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M249.863,0.117C165.601,3.316,98.358,73.413,98.462,157.735c0.052,42.703,17.097,81.422,44.733,109.768
              c16.422,16.842,27.48,37.956,31.884,60.702h61.229v-83.741l-46.744-46.744l27.849-27.849L256,208.459l38.589-38.589l27.849,27.849
              l-46.746,46.746v83.741h61.231c4.41-22.755,15.482-43.874,31.908-60.728c27.664-28.384,44.707-67.17,44.707-109.938
              C413.538,68.495,339.665-3.294,249.863,0.117z"/>
          </g>
        </g>
        <g>
          <line x1="256" y1="0" x2="256" y2="50" style="stroke:#ff00bf;stroke-width:2" />
          <line x1="512" y1="256" x2="462" y2="256" style="stroke:#FFFF00;stroke-width:2" />
          <line x1="256" y1="512" x2="256" y2="462" style="stroke:#FFFF00;stroke-width:2" />
          <line x1="0" y1="256" x2="50" y2="256" style="stroke:#FFFF00;stroke-width:2" />
        </g>
      </svg>
      <span class="name">
        Melispot
      </span>

      <span class="badge badge-soft-primary p-1">Beta</span>
    </a>

  <ul class="sidebar-nav">
    <li class="sidebar-item">
      <a class="sidebar-link" (click)="goToDashboard()">
        <i-feather class="mr-2" name="sliders"></i-feather>  <span class="align-middle">Dashboard</span>
      </a>
    </li>
    <li class="sidebar-item">
      <a data-bs-target="#auth" data-bs-toggle="collapse" class="sidebar-link collapsed">
        <span class="align-middle sidebar-icon">Visualizações</span>
      </a>
      <ul id="auth" class="sidebar-dropdown list-unstyled collapse show" data-bs-parent="#sidebar">
        <li class="sidebar-item"><a class="sidebar-link" (click)="goToItems()">Anuncios</a>
        </li>
        <li class="sidebar-item"><a class="sidebar-link" (click)="goToEvents()">Eventos</a>
        </li>
      </ul>
    </li>
    <li class="sidebar-item">
      <a data-bs-target="#auth" data-bs-toggle="collapse" class="sidebar-link collapsed">
        <span class="align-middle sidebar-icon">Minha Conta</span>
      </a>
      <ul id="auth" class="sidebar-dropdown list-unstyled collapse show" data-bs-parent="#sidebar">
        <li class="sidebar-item"><a class="sidebar-link" (click)="goToAccountConnect()">Conta MercadoLibre</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
