
<app-topbar></app-topbar>
<section class="landing-intro landing-bg pt-5 pt-lg-6 pb-5 pb-lg-7">
  <div class="alert alert-primary alert-dismissible fade show text-center mt-3" role="alert">
    <div class="container mt-3">
      <div class="col">
        <h4 class="alert-heading mb-3">🚀 Participe do lançamento beta como tester inicial. Seu feedback é crucial, completamente gratuito!</h4>
      </div>
      <div class="col mb-2">
        <p></p>
      </div>
    </div>
  </div>

  <div class="landing-intro-content container">
    <div class="row align-items-center mt-4">
      <div class="col-lg-5 mx-auto">
        <h2 class="font-weight-bold my-4" style="font-size: 2.3rem;">Transforme em Lucro seus Dados no Mercado Livre</h2>
        <p class="text-lg">Acompanhe alterações nos seus produtos como preços, promoções, atualizações de ficha técnica, garantias ou condições de envio dos seus produtos.</p>
        <ul class="text-lg mt-4">
          <li><strong>Identifique</strong> rapidamente o impacto das mudanças nas suas vendas.</li>
          <li><strong>Descubra</strong> se ajustes de preço, novas promoções ou foto de capa atraem mais visitantes.</li>
          <li><strong>Compreenda</strong> o que pode estar diminuindo o interesse em um produto.</li>
        </ul>
        <a (click)="goToSignup()" class="btn btn-primary btn-lg mt-4">Começar Teste Grátis</a>
      </div>
      <div class="col-lg-7 d-none d-lg-flex mx-auto text-center">
        <div class="landing-intro-screenshot pb-3">
          <img src="assets/images/homegraph.png" alt="Dark/Light Bootstrap Admin Template" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-6 bg-white">
  <div class="container position-relative z-3">
    <div class="row">
      <div class="col-md-12 mx-auto text-center">
        <div class="row">
          <div class="col-lg-10 col-xl-9 mx-auto">
            <div class="mb-4">
              <h2 class="h1 mb-3">Monitore todas as alterações dos seus produtos no Mercadolivre</h2>
              <p class="text-muted fs-lg">
                Rastreamos mais de 15 tipos de atualizações que podem impactar seus produtos no Mercadolivre. Isso inclui mudanças de preço, título, status de promoção, descrição do produto, atributos da ficha técnica e muito mais.
              </p>
            </div>
          </div>
        </div>
        <div class="row text-primary">
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="percent"></i-feather>
            <h4>Promoções Entrou ou Saiu</h4>
          </div>
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="dollar-sign"></i-feather>
            <h4>Mudanças de Preço</h4>
          </div>
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="image"></i-feather>
            <h4>Imagens e Videos Adicionados ou Excluidos</h4>
          </div>
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="image"></i-feather>
            <h4>Título, Categoria, e Descrição</h4>
          </div>
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="image"></i-feather>
            <h4>Atributos e Ficha Técnica</h4>
          </div>
          <div class="col-md-4 py-3">
            <i-feather class="align-middle mb-3" name="image"></i-feather>
            <h4>Tipos de Envío e Garantias</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-6">
  <div class="container">
    <div class="mb-5 text-center">
      <h2 class="h1">Comece Já! É Fácil e Rápido</h2>
      <p class="text-muted fs-lg">Crie sua conta no Melispot, vincule sua conta do Mercadolivre e comece a explorar suas métricas. Tudo de maneira gratuita e muito segura.</p>
    </div>
    <div class="row">
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch">
        <div class="card text-center border-0">
          <img src="assets/images/registration-form.png" width="60%" alt="Passo 1" class="img-fluid mx-auto d-block" />
          <div class="card-body">
            <h2>1. Crie sua Conta Teste</h2>
            <p class="lead">Registre-se na Melispot de forma rápida e gratuita. Só precisamos do seu nome, empresa e e-mail.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch">
        <div class="card text-center border-0">
          <img src="assets/images/shield.jpg" width="60%" alt="Passo 2" class="img-fluid mx-auto d-block" />
          <div class="card-body">
            <h2>2. Vincule sua conta Mercadolivre</h2>
            <p class="lead">Vincule sua conta do Mercado Livre à Melispot de forma simples e segura.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 d-flex align-items-stretch">
        <div class="card text-center border-0">
          <img src="assets/images/metrics.png" alt="Passo 3" class="img-fluid mt-3" width="200px" style="margin: auto;" />
          <div class="card-body">
            <h2>3. Monitore seus produtos</h2>
            <p class="lead">Inicie o acompanhamento e análise dos seus produtos em tempo real.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <a (click)="goToSignup()" class="btn btn-primary btn-lg mt-4">Começar Teste Grátis</a>
    </div>
  </div>
</section>



<section id="demos" class="py-6 bg-white">
  <div class="container position-relative z-3">
    <div class="row">
      <div class="col-md-12 mx-auto text-center">
        <div class="row">
          <div class="col-lg-10 col-xl-9 mx-auto">
            <div class="mb-4">
              <h2 class="h1 mb-3">No Mercado Livre, pequenas mudanças podem impactar diretamente a visibilidade dos seus produtos.</h2>
              <p class="text-muted fs-lg">
                Com a Melispot, transformamos essas complexidades em insights claros para você, ajudando a potencializar suas vendas.
              </p>
              <p class="text-muted fs-lg">Acompanhe de perto as variações e entenda como elas afetam o desempenho dos seus negócios. Conheça a Melispot e turbine suas vendas no Mercado Livre!</p>
            </div>
          </div>
        </div>
        <div class="row">
          <!--
          <div class="text-center">
            <img src="/assets/images/video-placeholder.jpeg" class="img-fluid rounded-lg landing-img d-block mx-auto" alt="Bootstrap 5 Dashboard Theme" />
            <h4>Video como funciona</h4>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</section>

<app-faq></app-faq>

<app-footer></app-footer>
