import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as AuthActions from '../../state/actions/auth.actions'
import { Subscription, take } from 'rxjs'
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-meli',
  templateUrl: './meli.component.html',
  styleUrls: ['./meli.component.scss']
  })

export class MeliComponent implements OnInit, OnDestroy {

  private clientIdSub: Subscription
  private _meliConnectSub: Subscription
  get window(): Window { return this.document.defaultView }


  constructor(private route: ActivatedRoute,
    @Inject(DOCUMENT) readonly document: Document,
    private _store: Store<fromRoot.State>) {
    }

  ngOnInit(): void {
    // console.log('MeliComponent - ngOnInit')
    this.clientIdSub = this._store.select(fromRoot.getClientId).subscribe( clientId => {
      if (clientId) {
        console.log('clientId: ', clientId)
        this._store.dispatch(AuthActions.AuthorizeAccountAndSet())
        }
      })

    this._meliConnectSub = this._store.select(fromRoot.isConnectedToMeli).subscribe( meliConnect => {
      if (meliConnect) {
        this.window.close()
        }
      })
    }


  ngOnDestroy(): void {
    if (this.clientIdSub) {
      this.clientIdSub.unsubscribe()
      }
    }


}
