import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromAuth from './auth.reducer'
import * as fromUi from './ui.reducer'
import * as fromSnackbar from './snackbar.reducer'


export interface State {
  auth:     fromAuth.AuthState
  ui:       fromUi.UIState
  snackbar: fromSnackbar.SnackbarState
  }

export const reducers: ActionReducerMap<State> = {
  auth:     fromAuth.AuthReducer,
  ui:       fromUi.UIReducer,
  snackbar: fromSnackbar.SnackbarReducer
  }

// UI //
export const getUiState     = createFeatureSelector<fromUi.UIState>('ui')
export const getUIIsLoader  = createSelector(getUiState, fromUi.getIsLoader)
export const getUIIsSpinner = createSelector(getUiState, fromUi.getIsSpinner)
export const getHideButton  = createSelector(getUiState, fromUi.getHideButton)
export const getItemSipnner  = createSelector(getUiState, fromUi.getItemSipnner)

// Auth //
export const getAuthState       = createFeatureSelector<fromAuth.AuthState>('auth')
export const getIsAuthenticated = createSelector(getAuthState, fromAuth.getIsAuth)
export const getLogoURL         = createSelector(getAuthState, fromAuth.getLogoURL)
export const getSignupInfo      = createSelector(getAuthState, fromAuth.getSignupInfo)
export const getClientId        = createSelector(getAuthState, fromAuth.getClientId)
export const getClientName      = createSelector(getAuthState, fromAuth.getClientName)
export const getSignUpInfo      = createSelector(getAuthState, fromAuth.getSignUpInfo)
export const getMeliUserId      = createSelector(getAuthState, fromAuth.getMeliUserId)
export const getUserId          = createSelector(getAuthState, fromAuth.getUserId)
export const getClientAdmin     = createSelector(getAuthState, fromAuth.getClientAdmin)
export const isMeliIn           = createSelector(getAuthState, fromAuth.getIsMeliIn)
export const getAuthToken       = createSelector(getAuthState, fromAuth.getAuthToken)
export const isConnectedToMeli     = createSelector(getAuthState, fromAuth.getMeliConnect)
