import { createAction, props } from "@ngrx/store";

export const GoToEnterPage      = createAction('[Routing] Go to Enter Page')
export const GoToItems          = createAction('[Routing] Go to Items')
export const GoToEvents          = createAction('[Routing] Go to Events')
export const GoToMeliIn         = createAction('[Routing] Go to Meli In')
export const GoToDashboard      = createAction('[Routing] Go to Dashboard')
export const GotoLoginPage      = createAction('[Routing] go to login page')
export const GotoSignUpPage     = createAction('[Routing] go to signup page')
export const GotoRecoverPasswPage = createAction('[Routing] go to recover password page')
export const GoToAccountConnect = createAction('[Routing] Go to account connect')
export const GoToOnboard        = createAction('[Routing] Go to onboard')

export const GoToHome         = createAction('[Routing] Go to home')
