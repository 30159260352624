import { Injectable } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, tap } from 'rxjs/operators'
import * as DialogsActions from '../actions/dialogs.actions'
import * as AuthActions from '../actions/auth.actions'
import * as UsersActions from '../actions/users.actions'
import { Store } from '@ngrx/store'
import * as fromRoot from '../reducers/app.reducer'
import { DialogConfirmationComponent } from '../../shared/dialog-confirmation/dialog-confirmation.component'
import { OnboardingComponent } from 'src/app/account/onboarding/onboarding.component'
@Injectable()
export class DialogsEffects {

  constructor(private actions$: Actions,
    private _store:             Store<fromRoot.State>,
    private _dialog:            MatDialog) {
    }

  // CLOSE DIALOG
  closeDialog$ = createEffect( () =>
    this.actions$.pipe(
      ofType(
        // Dialog Generic
        DialogsActions.CloseDialog,
        UsersActions.ActionSuccess),
      tap( () => this._dialog.closeAll())
      ),
    { dispatch: false}
    )

  openDialog$ = createEffect( () =>
    this.actions$.pipe(
      ofType(DialogsActions.OpenDialog),
      map( (payload) => {
          const cialogConfig = new MatDialogConfig()
          cialogConfig.disableClose = true
          cialogConfig.autoFocus = true
          cialogConfig.data = {
            element:      payload.element,
            elementId:    payload.elementId,
            elementType:  payload.elementType,
            action:       payload.action

         // DialogsActions.OpenDialog(DialogConfirmationComponent, cialogConfig)
          }
        })
      ),
      { dispatch: false }
    )

  }
