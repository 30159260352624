<section class="landing-footer py-6">
  <div class="container landing-footer-container text-center">
    <div class="row">
      <div class="col-12 col-md-9 col-lg-8 col-xl-6 mx-auto">
        <h2 class="h1 mb-3">
          Melispot Beta: Maximize seus Ganhos no Mercado Livre com Facilidade
        </h2>
        <a (click)="goToSignup()" class="btn btn-primary btn-lg mt-4">Começar Teste Grátis</a>
      </div>
    </div>
  </div>
</section>
