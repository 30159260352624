import { Component, OnInit } from '@angular/core'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Subscription, Observable } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { UIService } from '../../services/ui.service'
import { Router } from '@angular/router'
import { LoginInfo } from '../../interfaces/login-info.interface'
import * as Auth from '../../state/actions/auth.actions'
import { Store } from '@ngrx/store'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as RoutingActions from '../../state/actions/routing.actions'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup
  isSpinner$: Observable<boolean>
  private loginInfo: LoginInfo

  constructor(private router: Router,
    private store: Store<fromRoot.State>) { }

  ngOnInit() {
    // console.log('LoginComponent - ngOnInit')
    this.isSpinner$ = this.store.select(fromRoot.getUIIsSpinner)
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new UntypedFormControl('', { validators: [Validators.required] })
    })
  }


  onSubmit() {
    // console.log('LoginComponent - onSubmit - OnSignin Dispatched')
    this.loginInfo = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }
    this.store.dispatch(Auth.Login({ loginInfo: this.loginInfo }))
  }

  goToResetPassword() {
    console.log('LoginComponent - goToResetPassword')
    this.store.dispatch(RoutingActions.GotoRecoverPasswPage())
    }

  goToSignUp() {
    console.log('LoginComponent - goToResetPassword')
    this.store.dispatch(RoutingActions.GotoSignUpPage())
    }

  get email() {
    return this.loginForm.get('email')!
    }

  get password() {
    return this.loginForm.get('password')!
  }

}
