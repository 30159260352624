import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import * as AuthActions from '../actions/auth.actions'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import * as RoutingActions from '../actions/routing.actions'
import * as AccountActions from '../actions/account.actions'
import * as ItemEventsActions from '../actions/item-events.actions'
import * as fromRoot from '../reducers/app.reducer'
import *  as VisitsActions from '../actions/visits.actions'
import * as OrdersActions from '../actions/orders.actions'
import { concatMap, map, tap, withLatestFrom } from 'rxjs'
import { ROUTER_REQUEST } from '@ngrx/router-store'
import { AccountService } from 'src/app/services/account.service'

@Injectable()
export class RoutingEffects {

  onGoToEnterPage$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToEnterPage),
      tap( () => this.router.navigate(['/entry']))
      ),
      { dispatch: false}
    )

  onGoToSignup$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GotoSignUpPage),
      tap( () => this.router.navigate(['/signup']))
      ),
      { dispatch: false}
    )

  onGoToLoginPage$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GotoLoginPage),
      tap( () => this.router.navigate(['/login']))
      ),
    { dispatch: false}
    )

  onGotoAccountConnect$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToAccountConnect),
      tap( () => this.router.navigate(['account', 'connect']))
      ),
    { dispatch: false}
    )

  onGotoOnboard$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToOnboard),
      tap( () => this.router.navigate(['account', 'onboard']))
      ),
    { dispatch: false}
    )

  onGoToDashboard$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToDashboard),
      tap( () => this.router.navigate(['/dashboard']))
      ),
    { dispatch: false}
    )

  launchOnboard$ = createEffect( () =>
    this.actions$.pipe(
      ofType(AuthActions.LaunchOnboarding),
      map( () => RoutingActions.GoToOnboard())
      )
    )

  // Auth token happens only when user is connecting to mercadolivre
  setAuthenticated$ = createEffect( () =>
    this.actions$.pipe(
      ofType(AuthActions.SetAuthenticated),
      withLatestFrom(this.store.select(fromRoot.isMeliIn)),
      concatMap( ([action, isMeliIn])=> {
        if (isMeliIn) {
          return null
        }
        if (!action.isConnectedToMeli) {
          this.accountService.setClientId(action.authInterface.clientId)
          return [
            AuthActions.LaunchOnboarding(),
          ]
        } else {
          return [
            RoutingActions.GoToDashboard()
          ]
          }
        })
      )
    )

  onGoToItems$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToItems),
      tap( () => (this.router.navigate(['/dashboard', 'items']))),
      concatMap( () => {
        return [
          ItemEventsActions.RemoveAll(),
          VisitsActions.RemoveAll(),
          OrdersActions.RemoveAll()
          ]
      })
      )
    )

  onGoToEvents$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToEvents),
      tap( () => (this.router.navigate(['/dashboard', 'events']))),
      map( () =>  ItemEventsActions.RemoveAll())
      )
    )

  onGoToMeliIn$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToMeliIn),
      tap( () => (this.router.navigate(['/meli-in'])))
      ),
      { dispatch: false}
    )

  onGoToHome$ = createEffect( () =>
    this.actions$.pipe(
      ofType(RoutingActions.GoToHome),
      tap( () => (this.router.navigate(['/'])))
      ),
      { dispatch: false}
    )

  whatevers$ = createEffect( () =>
    this.actions$.pipe(
      ofType(ROUTER_REQUEST),
      concatMap( (action: any) => {
        if (action.payload.event.url.startsWith('/meli-in')) {
          const str = action.payload.event.url
          const match = str.match(/code=([^&]*)/)
          return [
            AuthActions.setAuthToken({authToken: match ? match[1] : null}),
            AuthActions.SetMeliInTrue()
          ]
        } else {
          return []
        }
        })
      )
    )

  constructor(
    private actions$:       Actions,
    private router:         Router,
    private store:          Store<fromRoot.State>,
    private accountService: AccountService
  ) {
  }
  }
