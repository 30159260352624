import { createAction, props } from '@ngrx/store'
import { SignupInfo, LoginInfo, ClientAdmin } from 'src/app/interfaces/auth.interface'

export const SetAuthenticated = createAction('[Auth] Set Authenticated', props<{ authInterface: ClientAdmin, isConnectedToMeli: boolean }>())
export const SaveSignInfoToState = createAction('[Auth] Save Sign info to state', props<{ signupInfo: SignupInfo }>())
export const SetSignUpAuthenticated = createAction('[Auth] Set Signup Authenticated', props<{ authInterface: ClientAdmin }>())
export const CreateAdminUserProfile = createAction('[Auth] Create admin user profile')
export const SetNotAuthenticated = createAction('[Auth] Set Not authenticated')
export const Login = createAction('[Auth] Login', props<{ loginInfo: LoginInfo }>())
export const Logout = createAction('[Auth] Logout')
export const Signup = createAction('[Signup] Signup', props<{ signupInfo: SignupInfo }>())
export const OnSignupSuccess = createAction('[Signup] On Signup Success', props<{ authInterface: ClientAdmin }>())
export const OnResetPassword = createAction('[Signup] On Reset Password',
  props<{ email: string }>()
)
export const UserLoginSetup = createAction('[Auth] User Login Setup', props<{ uid: string }>())
export const LoginSuccess = createAction('[Auth] Login Success') // This action doesn't do anything
export const OnResetPasswordSuccess = createAction('[Signup] On Reset Password Success')
export const DisplayLogoutMessage = createAction('[Auth] Display Logout Message')
export const ActionSuccess = createAction('[Auth] Action Success', props<{ action: string, meliUserId?: string}>())

export const InitDataRefreshed  = createAction('[Dashboard] Init Data Refreshed', props<{clientInfo: ClientAdmin}>())
export const AuthorizeAccountAndSet = createAction('[Auth] Authorize Account and Set')
export const SetMeliInTrue = createAction('[Auth] Set meli-in true')
export const setAuthToken           = createAction('[Account] Set Token', props<{authToken: string}>())
export const MeliConnected          = createAction('[Account] Meli Connected', props<{meliUserId: string}>())
export const LaunchOnboarding = createAction('[Auth] Launch onboarding')

// SUCCESS ACTIONS
export const MELI_ACCOUNT_EXISTS = 'Meli account exists'
export const MELI_ACCOUNT_CONNECTED = 'Meli account connected'
