import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, ParamMap, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import * as RoutingActions from '../../state/actions/routing.actions'
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  element = 'dashboard'
  routeSub: Subscription
  constructor(
    private router: Router,
    private store: Store
  ) {
   }
  ngOnDestroy(): void {
    this.routeSub.unsubscribe()
    }

  ngOnInit(): void {
    this.routeSub = this.router.events.subscribe( (event: any) => {
      if (event instanceof NavigationEnd) {
       this.activate(event.url)
        }
      })
    }

  activate(url: string) {
    if (url.includes('dashboard')) {
      this.element  = 'dashboard'
      }
    }

  goToAccountConnect() {
    this.store.dispatch(RoutingActions.GoToAccountConnect())
    }

  goToOnboard() {
      this.store.dispatch(RoutingActions.GoToOnboard())
    }

  goToDashboard() {
    this.store.dispatch(RoutingActions.GoToDashboard())
    }

  goToEvents() {
    this.store.dispatch(RoutingActions.GoToEvents())
    }

  goToItems() {
    this.store.dispatch(RoutingActions.GoToItems())
    }



}
