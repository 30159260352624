
<div>
  <mat-drawer-container autosize>
    <mat-drawer #sidenav mode="side" opened="true">
      <nav id="sidebar" class="sidebar">
        <app-sidebar></app-sidebar>
        </nav>
    </mat-drawer>

    <div class="main">
      <app-navbar></app-navbar>

      <main class="content">
        <div class="container-fluid p-0">
          <router-outlet></router-outlet>
        </div>
      </main>

      <footer class="footer">
        <div class="container-fluid">
          <div class="row text-muted">
            <div class="col-6 text-left">
              <ul class="list-inline">
                <!--
<li class="list-inline-item">
                  <a class="text-muted" href="#">Support</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Help Center</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Privacy</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Terms of Service</a>
                </li>
                -->

              </ul>
            </div>
            <div class="col-6 text-right">
              <p class="mb-0">
                &copy; 2023 - <a (click)="goToDashboard()" class="text-muted">Melispot</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </mat-drawer-container>
</div>
