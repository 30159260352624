import { Component, OnInit, OnDestroy } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { UIService } from '../../services/ui.service'
import { Subscription, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../state/reducers/app.reducer';
import * as Auth from '../../state/actions/auth.actions';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswForm: UntypedFormGroup
  isSpinner$: Observable<boolean>
  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit() {
    // console.log('ResetPasswordComponent - ngOnInit')
    this.isSpinner$ = this.store.select(fromRoot.getUIIsSpinner)
    this.resetPasswForm = new UntypedFormGroup({
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      })
    });

  }

  onSubmit() {
    // console.log('ResetPasswordComponent - onSubmit')
    this.store.dispatch(Auth.OnResetPassword({email: this.resetPasswForm.value.email}))
  }
}
