import { createAction, props } from '@ngrx/store'

export const ActionFailure        = createAction('[Errors] Action Failure', props<{error: any, func: string}>())
export const UserNotInDbLogout    = createAction('[Errors] User not in DB Logout')

// AUTH
export const AUTH_USER_NOT_FOUND   = '[Errors] Auth User not found'
export const AUTH_WRONG_PASSWORD   = '[Errors] Auth Wrong password'
export const AUTH_INVALID_EMAIL    = '[Errors] Auth Invalida email'
export const AUTH_EMAIL_IN_USED    = '[Errors] Auth email in used'
export const AUTH_WEAK_PASSWORD    = '[Errors] Auth weak password'
export const AUTH_USER_NOT_VALID   = '[Errors] Auth user not valid'

// SHARED
export const USERID_NOT_RECOGNIZED            = '[Shared] UserId not recognized'
export const OBJECT_NOT_FOUND       = '[Shared] Object not found'

// USERS
export const EMAIL_ALREADY_EXISTS = 'email-already-exists'
export const INVALID_EMAIL        = 'invalid-email'
export const INVALID_ARGUMENT     = 'invalid-argument'
export const FILE_PROCESS_ERROR   = 'file-process-error'
export const USER_NOT_IN_DATABASE   = '[Errors] User not in database'

// OTHER
export const SOMETHING_WENT_WRONG = '[Errors] Something went wrong'


