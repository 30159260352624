import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as RoutingActions from '../../state/actions/routing.actions'

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent {
  constructor(private store: Store<fromRoot.State>) { }
  goToSignup() {
    this.store.dispatch(RoutingActions.GotoSignUpPage())
  }
}
