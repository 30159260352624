import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './auth/auth.guard'
import { MeliOutComponent } from './auth/meli-out/meli-out.component'
import { IndexComponent } from './home/index/index.component'
import { MeliComponent } from './auth/meli/meli.component'
import { UnauthGuard } from './auth/unauth.guard'

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: 'meli-out',
    component: MeliOutComponent
    },
  {
    path: 'meli-in',
    component: MeliComponent
    },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard],
    },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
