import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import * as fromAccount from '../../state/reducers/account.reducer'
import * as fromRoot from '../../state/reducers/app.reducer'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-meli-out',
  templateUrl: './meli-out.component.html',
  styleUrls: ['./meli-out.component.scss']
})
export class MeliOutComponent implements OnInit {
  isSpinner$: Observable<boolean>
  get window(): Window { return this.document.defaultView }

  constructor(private _store: Store<fromRoot.State>, private route: ActivatedRoute,
    @Inject(DOCUMENT) readonly document: Document) {
   }

  ngOnInit(): void {
    console.log('MeliOutComponent - ngOnInit')
    this.isSpinner$ = this._store.select(fromRoot.getUIIsSpinner)
    this._store.select(fromAccount.getMeliAuthURL).pipe(take(1)).subscribe( url => {
      this.window.open(url, '_self')
    })
  }

}
