import { createAction, props } from "@ngrx/store"
import { Company } from "src/app/interfaces/company.interface"
import { User } from "src/app/interfaces/user.interface"

export const LoadCompanyToState = createAction('[Company] Load Company To State', props<{company: Company}>())
export const LoadCompany        = createAction('[Company] Load Company Info')
export const UpdateCompanyInfo  = createAction('[Company] Update Company Info', props<{company: Company}>())
export const ActionSuccess      = createAction('[Company] Action Success', props<{action: string, company?: Company, imageURL?: string}>())
export const AddLogoToCompany   = createAction('[Company] Add Logo to company', props<{file: File}>())

// SUCCESS ACTIONS
export const COMPANY_UPDATED  = 'Company Updated'
export const LOGO_UPLOADED    = 'Logo updated'
