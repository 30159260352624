import { Action, createReducer, on } from "@ngrx/store"
import { SignupInfo } from "src/app/interfaces/auth.interface"
import * as AuthActions from '../actions/auth.actions'
import * as AccountActions from '../actions/account.actions'

export interface AuthState {
  isAuthenticated:    boolean
  logoFileURL:        string,
  id:                 string,
  clientId:           string,
  clientName:         string,
  signupInfo:         SignupInfo,
  meliUserId:         string
  email:              string
  firstName:          string
  lastName:           string
  productsActive:     number
  productsInactive:   number
  productsPaused:     number
  isMeliIn:           boolean
  authToken:          string
  meliConnect:        boolean
  }

export const initialState: AuthState = {
  isAuthenticated:  false,
  id:               null,
  logoFileURL:      null,
  signupInfo:       null,
  clientId:         null,
  clientName:       null,
  meliUserId:       null,
  email:            null,
  firstName:        null,
  lastName:         null,
  productsActive:   null,
  productsInactive: null,
  productsPaused:   null,
  isMeliIn:         null,
  authToken:        null,
  meliConnect:      null
  }

const authReducer = createReducer(
  initialState,
  on(AuthActions.SetAuthenticated, (state, { authInterface, isConnectedToMeli }) => ({
    ...state,
    isAuthenticated:  true,
    id:               authInterface.adminId,
    clientId:         authInterface.clientId,
    clientName:       authInterface.clientName,
    meliUserId:       authInterface.meliUserId,
    productsActive:   authInterface.productsActive,
    productsInactive: authInterface.productsInactive,
    productsPaused:   authInterface.productsPaused,
    email:            authInterface.email,
    firstName:        authInterface.firstName,
    lastName:         authInterface.lastName,
    meliConnect:      isConnectedToMeli
    })),
  on(AuthActions.SaveSignInfoToState, (state, { signupInfo }) => ({
    ...state,
    signupInfo: signupInfo
    })),
  on(AuthActions.SetSignUpAuthenticated, (state, { authInterface }) => ({
    ...state,
    isAuthenticated:  true,
    id:               authInterface.adminId,
    clientId:         authInterface.clientId,
    clientName:       authInterface.clientName
    })),
  on(AuthActions.SetNotAuthenticated, (state) => ({
    ...state,
    isAuthenticated: false,
    userId: null
    })),
  on(AuthActions.Signup, (state, { signupInfo }) => ({
    ...state,
    signupInfo: signupInfo
    })),
  on(AuthActions.OnSignupSuccess, (state) => ({
    ...state,
    signupInfo: null
    })),
  on(AuthActions.InitDataRefreshed, (state, { clientInfo }) => ({
    ...state,
    productsActive:   clientInfo.productsActive,
    productsInactive: clientInfo.productsInactive,
    productsPaused:   clientInfo.productsPaused
    })),
    on(AuthActions.SetMeliInTrue, (state) => ({
      ...state,
      isMeliIn:   true
      })),
    on(AuthActions.setAuthToken, (state, { authToken }) => ({
      ...state,
      authToken: authToken
      })),
    on(AuthActions.MeliConnected, (state, { meliUserId }) => ({
      ...state,
      meliConnect: true,
      meliUserId: meliUserId
      })),
    on(AuthActions.setAuthToken, (state, { authToken }) => ({
      ...state,
      authToken: authToken
      })),
  )

export function AuthReducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action)
}

export const getIsAuth      = (state: AuthState) => state.isAuthenticated
export const getLogoURL     = (state: AuthState) => state.logoFileURL
export const getSignupInfo  = (state: AuthState) => state.signupInfo
export const getClientId    = (state: AuthState) => state.clientId
export const getClientName  = (state: AuthState) => state.clientName
export const getSignUpInfo  = (state: AuthState) => state.signupInfo
export const getUserId      = (state: AuthState) => state.id
export const getIsMeliIn    = (state: AuthState) => state.isMeliIn
export const getMeliUserId  = (state: AuthState) => state.meliUserId
export const getMeliConnect   = (state: AuthState) => state.meliConnect
export const getAuthToken   = (state: AuthState) => state.authToken
export const getClientAdmin  = (state: AuthState) => ({
  clientName: state.clientName,
  firstName: state.firstName,
  lastName: state.lastName,
  email: state.email,
  productsActive:  state.productsActive,
  productsInactive: state.productsInactive,
  productsPaused: state.productsPaused,
  meliUserId: state.meliUserId,
  meliConnect: state.meliConnect
})
