import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { SignupComponent } from './signup/signup.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'

const authRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    },
  {
    path: 'signup',
    component: SignupComponent,
    },
  {
    path: 'redefinir-senha',
    component: ResetPasswordComponent,
    }
]

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule {}
