import { Action, createReducer, on } from '@ngrx/store'
import * as snackbarActions from '../actions/snackbar.actions'

export interface SnackbarState {
  show: boolean
}

const initialState: SnackbarState = {
  show: false
}

const snackbarReducer = createReducer(
  initialState,
  on(snackbarActions.SnackbarOpen, state =>
    ({
      ...state,
      show: true
      })
    ),
  on(snackbarActions.SnackbarClose, state =>
    ({
      ...state,
      show: false
      })
    )
  )

export function SnackbarReducer(snackbarState: SnackbarState | undefined, action: Action) {
  return snackbarReducer(snackbarState, action)
  }
