import { createAction, props } from "@ngrx/store"
import { OnboardingReport } from "src/app/interfaces/account.interface"

export const GetMercadoLivrePermissionsUrl     = createAction('[Account] Get Mercadolivre permissions url')
export const CheckAccountState      = createAction('[Account] Check account status')
export const SetMeliAccountInfo     = createAction('[Account] Set Meli Account Info', props<{meliUserId: string}>())
export const AddRedirectURI         = createAction('[Account] Add Redirect URI', props<{meliState: boolean, url: string}>())
export const ActionSuccess          = createAction('[Account] Action Success', props<{action: string, reportId?: string}>())
export const getClientId            = createAction('[Account] Set FireListenForOdersColllientId', props<{uid: string}>())
export const SetClientId            = createAction('[Account] Set ClientId', props<{clientId: string}>())
export const FireIinitalImportFromMeli = createAction('[Account] Fire initial import from Meli', props<{meliUserId: string}>())
export const InitialImportFromMeliFired = createAction('[Account] Initial import from Meli fired', props<{reportId: string}>())
export const UpdateOnboardingReport = createAction('[Auth] Update onboarding report', props<{report: OnboardingReport}>())
export const UpdateFirestoreOnboardingReport = createAction('[Auth] Update firestore onboarding report', props<{reportId: string, itemsProcessed?: number, ordersProcessed?: number}>())
export const FireListenForOdersProcessedColl = createAction('[Account] Fire listen for orders processed collection', props<{reportId: string}>())
export const FireListenForItemsProcessedColl = createAction('[Account] Fire listen for items processed collection', props<{reportId: string}>())
export const MoveOrdersToBigquery   = createAction('[Account] Move orders to bigquery', props<{reportId: string}>())
export const MoveVisitsToBigquery   = createAction('[Account] Move visits to bigquery', props<{reportId: string}>())
export const FinishOnboardReport  = createAction('[Account] Finalize onboard report', props<{reportId: string}>())
// SUCCESS ACTIONS
export const MELI_ACCOUNT_EXISTS    = 'Meli account exists'
export const CLIENT_INFO_LOADED     = 'Client info loaded'
export const INITIAL_IMPORTS_FIRED  = 'Initial imports fired'
