<section class="start-container" fxLayout fxLayout="row" fxLayoutAlign="center start">
  <mat-card fxFlex.xs="100%" fxFlex="400px">
    <mat-card-header>
      <mat-card-title><h1>Recebendo dados do Mercadolivre</h1></mat-card-title>
    </mat-card-header>
    <mat-card-content class="spinner-container" fxLayout fxLayoutAlign="center center">
      <mat-spinner [diameter]="25"></mat-spinner>
    </mat-card-content>
  </mat-card>
</section>
