import { createAction, props } from '@ngrx/store'
import { User } from 'src/app/interfaces/user.interface'

export const LoadUsers          = createAction('[Users] Load')
export const CreateUser         = createAction('[Users] Create', props<{user: User}>())
export const UpdateUser         = createAction('[Users] Update', props<{user: User}>())
export const DeleteUser         = createAction('[Users] Delete', props<{user: User}>())
export const UserErrorRepeated  = createAction('[Users] Error Repeated')
export const ActionSuccess      = createAction('[Users] Action Success', props<{action: string, users?: User[]}>())

// CSV
export const CreateUsersByCSV           = createAction('[Users] Create Users via csv', props<{file: File}>())
export const CSVFileProcessFinished     = createAction('[Users] CSV file process finished')
export const FileProcessFailure         = createAction('[Users] File process failure')
export const CSVFileProcessed           = createAction('[Users] CSV file processed', props<{count: number, reportId: string}>())

/// NGRX ENTITY ///
export const Added        = createAction('[Users] added',    props<{user: User}>())
export const Modified     = createAction('[Users] modified', props<{user: User}>())
export const RemoveOne    = createAction('[Users] removed',  props<{user: User}>())
export const AddUsers     = createAction('[Users] Add Many', props<{users: User[]}>())
export const RemoveUsers  = createAction('[Users] Remove All')

// SUCCESS ACTIONS
export const USERS_LOADED    = 'Users loaded'
export const USER_CREATED    = 'User created'
export const USER_UPDATED    = 'User udpated'
export const USER_DELETED    = 'User deleted'
