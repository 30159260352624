import { Injectable } from '@angular/core'
import { Observable, firstValueFrom, take } from 'rxjs'
import { AUTHORIZE_AND_SET, CONNECT_ACCOUNT, FIRE_INITIAL_IMPORTS, FIRE_MOVE_ORDERS_TO_BIGQUERY, FIRE_MOVE_VISITS_TO_BIGQUERY } from '../interfaces/firebase-functions.interface'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { chunk } from 'simple-statistics'
import { OnboardingReport } from '../interfaces/account.interface'

@Injectable()
export class AccountService {

  firestore = this.db.firestore
  private clientId: string

  constructor(private fns: AngularFireFunctions,
    private db: AngularFirestore,
    ) {
      console.log('AccountService instantiated!')
    }

  setClientId(clientId: string) {
    this.clientId = clientId
    console.log('AccountService: ', this.clientId)
    }

  async connectToMeliAccount() {
    console.log('connectAccount: ', this.clientId)
    return new Promise(async (resolve, reject) => {
      try {
        const connectAccount$ = this.fns.httpsCallable(CONNECT_ACCOUNT)
        const result = await firstValueFrom(connectAccount$({clientId: this.clientId}).pipe(take(1)))
        // state: false, redirectUri: redirectUri
        // console.log(result)
        resolve(result)
      } catch (error) {
        return reject(error)
        }
      })
    }

  async fireIinitalImportFromMeli(meliUserId: string): Promise<{reportId: string}> {
    return new Promise(async (resolve, reject) => {
      try {
        // 1. Set onboarding report
        const reportId = this.db.createId()
        const report: OnboardingReport = {
          items: 0,
          itemsProcessed: 0,
          orders: 0,
          ordersProcessed: 0,
          ordersSentToBigquery: 0,
          reportId: reportId,
          status: 'active',
          movedOrdersToBg: false,
          movedVisitsToBg: false,
          visitsSentToBigquery: 0
        }
        await this.firestore.doc('clients/' + this.clientId + '/reports/' + reportId).set(report)

        // 2. Fire initial imports
        const fireInitialImports$ = this.fns.httpsCallable(FIRE_INITIAL_IMPORTS)
        const result = await firstValueFrom(fireInitialImports$({clientId: this.clientId, reportId: reportId, meliUserId: meliUserId}).pipe(take(1)))
        resolve({reportId: reportId})
      } catch (error) {
        return reject(error)
        }
      })
    }


  async checkAccountState(meliUserId: string) {
    // console.log('checkAccountState: ')
    let result: any = {
      meliUserId: meliUserId,
      state: null
    }
    return new Promise(async (resolve, reject) => {
      try {
        const accounts = await this.firestore.collection('clients/').where('meliUserId', '==', meliUserId).limit(1).get()
        const account = accounts.docs[0].data() as any
        if (!accounts.empty && account.signUpCompleted === true) {
          result.state = true
          resolve(result)
        } else {
          result.state = false
          resolve(result)
        }
      } catch (error) {
        return reject(error)
          }
        })
      }

  async moveOrdersToBigquery(reportId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const moveOrdersToBigquery$ = this.fns.httpsCallable(FIRE_MOVE_ORDERS_TO_BIGQUERY)
        const result = await firstValueFrom(moveOrdersToBigquery$({clientId: this.clientId, reportId: reportId}).pipe(take(1)))

        const updateReportMoveOrdersToBg = this.firestore.doc('clients/' + this.clientId + '/reports/' + reportId).update({
          movedOrdersToBg: true
        })
        await updateReportMoveOrdersToBg
        resolve(true)
      } catch (error) {
        return reject(error)
        }
      })
    }

  async moveVisitsToBigquery(reportId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const moveVisitsToBigquery$ = this.fns.httpsCallable(FIRE_MOVE_VISITS_TO_BIGQUERY)
        const result = await firstValueFrom(moveVisitsToBigquery$({clientId: this.clientId, reportId: reportId}).pipe(take(1)))

        const updateReportMoveVisitsToBg = this.firestore.doc('clients/' + this.clientId + '/reports/' + reportId).update({
          movedVisitsToBg: true
        })
        await updateReportMoveVisitsToBg
        resolve(true)
      } catch (error) {
        return reject(error)
        }
      })
    }

  listenInitialReportChanges(reportId: string): Observable<any> {
    return this.db.doc(`clients/${this.clientId}/reports/${reportId}`).valueChanges()
    }

  listenToClientMeliConnect(): Observable<any> {
    return this.db.doc(`clients/${this.clientId}`).valueChanges()
    }

  listenToClientInitialOrdersColl(): Observable<any> {
    return this.db.collection(`clients/${this.clientId}/orders`).valueChanges()
    }

  listenToItemsProcessedColl(): Observable<any> {
    return this.db.collection(`clients/${this.clientId}/items`, ref => ref.where('processed', '==', true)).valueChanges()
    }

  listenToItemsPromotionsProcessedColl(): Observable<any> {
    return this.db.collection(`clients/${this.clientId}/items`, ref => ref.where('promotionsProcessed', '==', true)).valueChanges()
    }

  async udpateOnboardReport(reportId: string, itemsProcessed?: number, ordersProcessed?: number, status?: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const onboardReportRef = this.firestore.doc('clients/' + this.clientId + '/reports/' + reportId)
        let updateOnboardReport
        if (itemsProcessed) {
          updateOnboardReport =  onboardReportRef.update({itemsProcessed: itemsProcessed})
        }
        if (ordersProcessed) {
          updateOnboardReport =  onboardReportRef.update({ordersProcessed: ordersProcessed})
        }
        if (status) {
          updateOnboardReport =  onboardReportRef.update({status: status})
        }
        await updateOnboardReport
        resolve(true)
      } catch (error) {
        return reject(error)
        }
      })
    }

}
