import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators'
import * as ErrorsActions from '../actions/errors.actions'
import * as AuthActions from '../actions/auth.actions'

@Injectable()
export class ErrorsEffects {

  constructor(private actions$: Actions) {}

  logError$ = createEffect( () =>
    this.actions$.pipe(
      ofType(ErrorsActions.ActionFailure),
      tap( (action) => console.error(action)),
      concatMap( (action) => {
        console.log(action.error, action.func)
        switch (action.error) {
          // AUTH
          case ErrorsActions.USER_NOT_IN_DATABASE: {
            return [ErrorsActions.UserNotInDbLogout()]
            }
          default: {
            return null
            }
          }
        })
      )
    )

    userNotInDbLogout$ = createEffect( () =>
      this.actions$.pipe(
        ofType(ErrorsActions.UserNotInDbLogout),
        map( () => AuthActions.Logout() )
        )
      )

  }
