import { Component, OnInit, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import * as fromRoot from '../../state/reducers/app.reducer'
import * as Auth from '../../state/actions/auth.actions'
import { SignupInfo } from 'src/app/interfaces/auth.interface'
import * as RoutingActions from '../../state/actions/routing.actions'
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})

@Injectable()
export class SignupComponent implements OnInit {

  signupForm: UntypedFormGroup
  isSpinner$: Observable<boolean>
  singupInfo: SignupInfo

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    // console.log('SignupComponent - ngOnInit')
    this.isSpinner$ = this.store.select(fromRoot.getUIIsSpinner)
    this.signupForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      lastName: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      client: new UntypedFormControl('', {
        validators: [Validators.required]
      }),
      email: new UntypedFormControl('', {
        validators: [Validators.required, Validators.email]
      }),
      password: new UntypedFormControl('', {
        validators: [Validators.required, Validators.minLength(6)]
      })
    })
  }

  onSubmit() {
    // console.log('SignupComponent - onSubmit')
    this.singupInfo = ({
      firstName: this.signupForm.value.firstName,
      lastName: this.signupForm.value.lastName,
      clientName: this.signupForm.value.client,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      role: 'admin',
      deleted: false,
      state: 'active',
      meliConnect: false
    })
    this.store.dispatch(Auth.Signup({ signupInfo: this.singupInfo }))
  }
  get firstName() {
    return this.signupForm.get('firstName');
  }
  get lastName() {
    return this.signupForm.get('lastName');
  }
  get clientName() {
    return this.signupForm.get('client');
  }
  get email() {
    return this.signupForm.get('email');
  }
  get password() {
    return this.signupForm.get('password');
  }

  goToLogin() {
    this.store.dispatch(RoutingActions.GotoLoginPage())
  }

}
