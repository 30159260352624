// API FUNCTIONS
// NEW
export const CONNECT_ACCOUNT    = 'connectAccount'
export const AUTHORIZE_AND_SET  = 'authorizeAndSet'
export const FIRE_INITIAL_IMPORTS  = 'onboard-fireInitialImports'
export const FIRE_MOVE_ORDERS_TO_BIGQUERY  = 'onboard-fireMoveOrdersToBigquery'
export const FIRE_MOVE_VISITS_TO_BIGQUERY  = 'onboard-fireMoveVisitsToBigquery'
export const GET_EVENTS         = 'bigquery-getEvents'
export const GET_VISITS         = 'bigquery-getVisits'
export const GET_ORDERS_COUNT   = 'bigquery-getOrdersCount'
export const GET_TOTAL_SALES    = 'bigquery-getTotalSales'
export const GET_SALES_BY_DATE  = 'bigquery-getSalesByDate'
export const GET_CATEGORIES_BY_DATE  = 'bigquery-getCategoriesByDate'
export const GET_TOP_OR_WORST_ITEMS_BY_DATE  = 'bigquery-getTopWorstItemsByDate'
